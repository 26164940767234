.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg {
  width: 100vw;
}

.bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.bgWrapper {
  padding-block: 1rem 30rem;
}

.h2Wrapper {
  margin-bottom: -6.5rem;
  position: relative; 
  z-index: 10;
}

.h2 {
  font-size: 2.5rem;
  transform: translateY(7rem);
}

.bar {
  background-color: rgba(0,0,0,0.5);
  padding: 15rem 1rem 3rem 0;
  width: 100vw;
}

.icon {
  transition: all 0.5s ease-in-out;
}

.fan {
  animation: rotate 4s infinite linear;
  color: steelblue;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.house {
  color: orange;
}

.wrench {
  color: gray;
}
.tech {
  color: rgb(14, 170, 68);
}
.dollar {
  animation: spin 4s infinite linear;
  background-color: gold;
  border: 3px solid gray;
  border-radius: 50%;
  color: gray;
}

@keyframes spin {
  0% {
    rotate: y 0deg;
  }
  50% {
    rotate: y 180deg;
  }
  100% {
    rotate: y 360deg;
  }
}
.meter {
  color: rgb(133, 193, 235);
}

.finance {
  background-color: orange;
  border: none;
  border-radius: 2px;
  color: white;
  font-size: 1.5rem;
  padding: 1rem 2rem;
  transition: all 0.25s ease-in-out;
}

.finance:hover {
  background-color: skyblue;
  color: white;
  transform: scale(1.1);
}

.financeInfo {
  list-style-type: none;
  padding: 0; 
  margin: 0; 
}