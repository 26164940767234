.divWrapper {
  background-color: rgba(0,0,0,0.6);
}

.div {
  max-width: 1200px;
  margin: 0 auto;
}

.row {
  padding-block: 6rem 0rem;
}

.colLeft {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 0 3rem 1rem;
}

.colLeft>*{
  color: white;
  cursor: pointer;
}

.colLeft>a:hover{
  color: white;
}

.colRight {
  padding: 1rem 0;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 1rem;
}

.footer>a {
  text-decoration: none;
}

.footer>a>span {
  font-style: italic;
}

.footer>a>img {
  animation: slowSpin 5s ease-in-out infinite;
  -webkit-filter: drop-shadow(1px 1px 1px black);
  filter: drop-shadow(1px 1px 1px black);
  padding-bottom: 0.25rem;
}

@keyframes slowSpin {
  0% {
      transform: scale(1);
  }

  50% {
      transform: scale(-1, 1);
  }

  100% {
      transform: scale(1);
  }
}

.link {
  align-self: center;
  display: flex;
  transition: all 0.25s ease-in;
}

.link:hover {
  transform: scale(1.05);
}