.headerWrapper {
  display: flex;
  justify-content: center;
}

.header {
  background-image: url('../../assets/images/house.jpg');
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
  height: 47.5rem;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.layer {
  background-color: rgba(0,0,0,0.4);
  height: 47.5rem;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  z-index: 10;
}

.logo {
  top: 4rem;
  right: 3rem;
  width: 5rem;
  position: absolute;
}

.text {
  margin: 0 auto;
  max-width: 1200px;
  padding-left: 2rem;
  position: relative;
  transform: translateY(-85vh);
  z-index: 11;
}

.h1 {
  font-size: clamp(3rem, 18vw, 4.5rem);
  font-weight: 500;
  letter-spacing: 0.25rem;
}

.span {
  font-size: clamp(1.5rem, 7vw, 2rem);
  font-weight: 300;
  letter-spacing: 0.5rem;
}

.h2 {
  font-weight: 300;
  letter-spacing: 2px;
  line-height: 2rem;
  padding-block: clamp(2rem, 10vmin, 5rem);
  padding-inline: 0.5rem;
}

.h3 {
  color: white;
  font-weight: 300;
  padding: 3rem 2rem 0 2rem;
}

@media (max-width: 575px) {
  .text {
    padding-left: 1rem;
  }
  .logo {
    top: 4rem;
    right: 1rem;
    width: 3rem;
  }
}

@media (orientation: portrait) and (min-width: 750px) and (min-height: 950px){
  .h1 {
    font-size: clamp(4rem, 20vh,6rem);
  }
  .h2 {
    font-size: 2rem;
    line-height: 3rem;
    padding-block: clamp(5rem, 25vh, 8rem);
  }
  .button {
    font-size: 2rem;
    padding: 1rem 2rem;
  }
  .h3 {
    font-size: 2rem;
    padding-block: clamp(5rem, 25vh, 8rem);
  }
}

@media (max-width: 375px) {
  .text {
    transform: translateY(-95vh);
    padding-left: 0.5rem;
  }
}

@media (max-width: 300px) {
  .text {
    transform: translateY(-100vh);
  }
}

@media (orientation: landscape) and (max-height: 700px) {
  .text {
    transform: translateY(-100vh);
  }
}

@media (orientation: landscape) and (max-height: 600px) {
  .text {
    transform: translateY(-110vh);
  }
}

@media (orientation: landscape) and (max-height: 500px) {
  .text {
    transform: translateY(-150vh);
  }
}

@media (orientation: landscape) and (max-height: 400px) {
  .text {
    transform: translateY(-180vh);
  }
}
