.contactWrapper {
background-color: gainsboro;
padding-top: 3rem;
margin-top: -3rem;
}

.contact {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1200px;
}

.bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.bgWrapper {
  padding-block: 1rem 15rem;
}

.h2Wrapper {
  margin-bottom: -6.5rem;
  position: relative; 
  z-index: 10;
}

.h2 {
  font-size: 2.5rem;
  transform: translateY(6rem);
}

.bar {
  background-color: rgba(0,0,0,0.5);
  padding: 15rem 1rem 0rem 0;
  width: 100vw;
}

.h3 {
  border-bottom: 2px solid black;
  padding-bottom: 0.25rem;
  width: fit-content;
}

.link {
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  margin: 0 auto;
  width: fit-content;
}

.van {
  animation: drive 4s ease-in-out infinite 1s;
  transition: all 0.5s ease-in-out;
}

@keyframes drive {
  0% {
    transform: translateX(-4rem);
  }
  5% {
    transform: translateX(-4rem) rotate(0deg);
  }
  20% {
    transform: translateX(-4rem) rotate(-15deg);
  }
  25% {
    transform: translateX(-4rem) rotate(-15deg);
  }
  40% {
    transform: translateX(-4rem) rotate(0deg);
  }
  95% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: translateX(4rem);
  }
}

.arrow {
  animation: down 2s ease-in-out infinite alternate-reverse;
}

@keyframes down {
  100% {
    transform: scale(-1, 1) translateY(-3rem);
  }
}

.contact:hover .link>.icon {
  animation: beat 2s infinite ease-in;
}

.icon {
  background-color: red;
  border-radius: 100vw;
  padding: 0.25rem 0.5rem 0 0.5rem;
  transition: all 0.25s ease-in-out;
  transform: translateY(-0.75rem);
}

@keyframes beat {
  0% {
    transform: translateY(-0.75rem) scale(1);
  }
  5% {
    transform: translateY(-0.75rem) scale(1.2);
  }
  20% {
    transform: translateY(-0.75rem) scale(1);
  }
  35% {
    transform: translateY(-0.75rem) scale(1.3);
  }
  50% {
    transform: translateY(-0.75rem) scale(1);
  }
  100% {
    transform: translateY(-0.75rem) scale(1);
  }
}

.calendly {
  align-items: center;
  background-color: white;
  border: 1px solid lightslategray;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  margin: 2rem auto 3rem auto;
  padding: 1rem;
  width: fit-content;
}

.calendly:hover {
  background-color: cornflowerblue;
  border-color: transparent;
  box-shadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
  color: white;
  transition: all 0.25s ease-in-out;
}

.calendly p {
  margin: auto 0;
}

.p {
  color: black;
  font-weight: 300;
}

.iframe {
  margin-bottom: -1rem;
  height: 30rem;
  width: 100vw;
}