.row {
  display: flex;
  justify-content: center;
  margin-block: 4rem;
  max-width: 1200px;
}

.col {
  margin-block: 5rem;
  padding-inline: 2rem;
  transition: all 0.25s ease-in-out;
}

.col>* {
  margin-block: 1rem;
}

.col:hover {
  background-color: whitesmoke;
  border-radius: 2px;
}