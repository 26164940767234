.button {
  background-color: orange;
  border: none;
  border-radius: 2px;
  color: white;
  font-size: 1.5rem;
  margin-left: 0.5rem;
  padding: 1rem 2rem;
  transition: all 0.25s ease-in;
}

.button:hover {
  background-color: skyblue;
  color: white;
  transform: scale(1.1);
}