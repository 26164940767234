.about {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.bgWrapper {
  padding-block: 1rem 15rem;
}

.h2Wrapper {
  margin-bottom: -6.5rem;
  position: relative; 
  z-index: 10;
}

.h2 {
  font-size: 2.5rem;
  transform: translateY(7rem);
}

.bar {
  background-color: rgba(0,0,0,0.5);
  padding: 15rem 1rem 0rem 0;
  width: 100vw;
}

.rowWrapper {
  background-color: rgba(0,0,0,0.5);
  padding-top: 15rem;
  width: 100vw;
}

.buttonWrapper {
  margin-bottom: 10rem;
}

.col {
  transition: background-color 0.25s ease-in-out;
}

.col:hover {
  background-color: whitesmoke;
}

.cities {
  list-style-type: none; 
  padding: 0; 
  margin: 0; 
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

@media (max-width: 575px) {
  .cities {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  }
}
