.scrollTainer {
  padding: 2rem 0;
  background-color: transparent;
  white-space: nowrap;
  overflow-x: hidden;
}

.image {
  display: inline-block;
  text-align: center;
  margin: auto;
  transition: all 0.25s ease-in-out;
}