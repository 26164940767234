@import url('https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap');

.fontPrompt {
  font-family: 'Prompt', sans-serif;
}

a,a:visited {
  text-decoration: none;
  text-shadow: 0px 0px 3px rgba(117, 117, 117, 0.12);
}

.textSilver {
  color: silver;
}

.textGray {
  color: gray;
}

.cornflowerBlue {
  color: cornflowerblue;
}

.textShadow {
  text-shadow: 1px 1px 1px black;
}

.textShadowWhite {
  text-shadow: 1px 1px 1px white;
}