.bg {
  background-color: rgba(27, 133, 208, 0.75);
}

.bgClip {
  -webkit-clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
  clip-path: polygon(0 0, 100% 0%, 100% 100%, 0 70%);
}

.bgImg {
  animation: bgParallax 500s ease-in-out infinite alternate-reverse;
  background-attachment: fixed;
  background-image: url('../assets/images/beach.jpg');
  background-size: 100%;
  background-position: center;
}

@keyframes bgParallax {
  0% {
    background-position: bottom;
  }
  100% {
    background-position: top;
  }
}
